import React, { PureComponent } from 'react';
import DisplayHours from './DisplayHours';
import { Phone, Globe, Pin } from '../../assets/icons';

import css from './SidePanel.module.scss';

class LocationDetails extends PureComponent {
  render() {
    const { location } = this.props;
    const web =
      location.website && !location.website.match(/https?/)
        ? `https://${location.website}`
        : location.website;
    return (
      <div className={css.details}>
        {(location.street ||
          location.street2 ||
          location.city ||
          location.state ||
          location.postal_code) && (
          <div className={css.address}>
            <Pin />
            <span>
              {location.street && location.street}
              {location.street2 ? ` ${location.street2}` : ''},
              {` ${location.city}, ${location.state}, ${location.postal_code}`}
            </span>
          </div>
        )}
        {location.phone && (
          <div>
            <Phone />
            <a href={`tel:${location.phone}`}>{location.phone}</a>
          </div>
        )}
        {location.website && (
          <div>
            <div>
              <Globe />
            </div>
            <a
              href={web}
              className={css.truncate}
              title={location.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {location.website}
            </a>
          </div>
        )}
        {location.hours && <DisplayHours hours={location.hours} />}
      </div>
    );
  }
}

export default LocationDetails;
