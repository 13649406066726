import React, { PureComponent } from 'react';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Modal, ModalBody } from 'reactstrap';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share';
import css from './SidePanel.module.scss';

class ShareModal extends PureComponent {
  render() {
    const { location, ...props } = this.props;
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
        <ModalBody>
          <div>
            <h2 className="h2 text-center" style={{ fontWeight: '600' }}>
              Share
            </h2>
            <div className="grid justify-center align-center">
              <div className="pad">
                <EmailShareButton
                  url={window.location.href}
                  subject={`Virtual Tour of ${location.name}`}
                  body={'Check out this Virtual Tour I found on Threshold 360!'}
                  separator={'\n'}
                  title="Share to email"
                >
                  <EmailIcon size={'4rem'} round={true} />
                </EmailShareButton>
              </div>
              <div className="pad">
                <FacebookShareButton
                  url={window.location.href}
                  title="Share to facebook"
                  hashtag="threshold360"
                >
                  <FacebookIcon size={'4rem'} round={true} />
                </FacebookShareButton>
              </div>
              <div className="pad">
                <TwitterShareButton
                  url={window.location.href}
                  title="Share to twitter"
                  via="threshold360"
                  hashtags={['threshold360']}
                  related={['threshold360']}
                >
                  <TwitterIcon size={'4rem'} round={true} />
                </TwitterShareButton>
              </div>
              <div className="pad">
                <LinkedinShareButton
                  url={window.location.href}
                  title="Share to linkedin"
                  source="Threshold 360"
                >
                  <LinkedinIcon size={'4rem'} round={true} />
                </LinkedinShareButton>
              </div>
            </div>
            <div className={`grid full align-center ${css.shareLink}`}>
              <SyntaxHighlighter
                className="full"
                language="text"
                style={atomDark}
                showLineNumbers={false}
                wrapLines={true}
              >
                {window.location.href}
              </SyntaxHighlighter>
              <span className={css.copyButton}>
                <CopyToClipboard text={window.location.href}>
                  <Button size="sm">Copy</Button>
                </CopyToClipboard>
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ShareModal;
