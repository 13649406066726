import React, { useEffect, useState } from 'react';
import css from './SidePanel.module.scss';

export const LeadGenPanel = ({ location, isShowingLeadGenPanel }) => {
  const [leadGen, setLeadGen] = useState(null);
  const id = location.ll_id;
  // used for testing
  // const id = 52297257;
  const getLeadGenImage = async () => {
    if (id) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_NODE_API}/licensee-locations/${id}/lead-generation`,
          {
            headers: {
              'Content-Type': 'application/json',
              'auth-token': 'eqUj7WNhjz-X'
            },
            method: 'GET'
          }
        );
        if (res.ok) {
          const json = await res.json();
          setLeadGen(json);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getLeadGenImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnalytics = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_ANALYTICS}?m=14&li=${location.licensee_id}&lo=${location.id}v=1`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'GET'
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return isShowingLeadGenPanel &&
    leadGen &&
    leadGen.leadGenerationImage &&
    leadGen.leadGenerationUrl ? (
    <div className={css.leadGenWrapper}>
      <a
        href={leadGen.leadGenerationUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleAnalytics}
      >
        <div
          className={css.leadGenContainer}
          style={{
            backgroundImage: `url(${leadGen.leadGenerationImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        />
      </a>
    </div>
  ) : null;
};
