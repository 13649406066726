import React from 'react';
import css from './SidePanel.module.scss';
import { ShowMore } from '../../assets/icons';
import cn from 'classnames';

const ClosedPanel = ({ location, activeStep = {}, toggle }) => {
  const hasCTA = location.cta_url && location.cta_label;
  return (
    <div className={css.titleContainer}>
      {location.licensee && location.licensee.logo_url && (
        <img
          className={css.logo}
          src={location.licensee.logo_url}
          alt={`${location.name} logo`}
        />
      )}
      <div className={cn(css.title)}>
        <div
          className={cn(css.locationName, css.truncate)}
          title={location.name}
        >
          {location.name}
        </div>
        <div
          className={cn(css.activeStepName, css.truncate)}
          title={activeStep.name}
        >
          {activeStep.name}
        </div>
      </div>
      <button className={css.multiStep} onClick={toggle}>
        <ShowMore />
      </button>
      {hasCTA && (
        <a
          className={css.ctaButton}
          href={location.cta_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {location.cta_label}
        </a>
      )}
    </div>
  );
};

export default ClosedPanel;
