import React from 'react';
import { convertHours, getWeekday } from '../../lib/dateTime';
import { Clock, CaratDown } from '../../assets/icons';
import css from './SidePanel.module.scss';
import cn from 'classnames';

const weekdays = [
  ['Sun', 'Sunday'],
  ['Mon', 'Monday'],
  ['Tue', 'Tuesday'],
  ['Wed', 'Wednesday'],
  ['Thur', 'Thursday'],
  ['Fri', 'Friday'],
  ['Sat', 'Saturday']
];

const ViewHours = ({
  hours,
  alwaysOpen,
  hide = false,
  dayIndex = getWeekday()
}) => {
  var index = -1;
  hours.some(function(el, i) {
    if ((el.open || {}).day === dayIndex) {
      index = i;
      return true;
    }
  });

  const day = {
    open: (hours[index] || {}).open,
    close: (hours[index] || {}).close
  };
  const { open = {}, close = {} } = day;
  const closed = !alwaysOpen && !day.open && !day.close;

  if (hide) {
    return null;
  }
  if (closed) {
    return <span>Closed</span>;
  }
  if (!closed) {
    return (
      <span>
        {convertHours(open.time || '0000')} -{' '}
        {convertHours(close.time || '0000')}
      </span>
    );
  }
};

class DisplayHours extends React.PureComponent {
  state = {
    showHours: false
  };

  toggleHours = e => {
    e.preventDefault();
    this.setState({ showHours: !this.state.showHours });
  };

  render() {
    const { hours } = this.props;
    const { showHours } = this.state;
    if (!hours) return null;

    const alwaysOpen = hours.length === 1 && hours[0].open.day === 0;
    return (
      <div className={css.hours}>
        <Clock />
        <div className={css.info}>
          <div className={css.hoursTitle}>
            <label htmlFor="toggleHours">
              <span>Hours</span>
              <ViewHours
                hours={hours}
                alwaysOpen={alwaysOpen}
                hide={showHours}
              />
            </label>
            <button
              id="toggleHours"
              className={cn(css.toggleHours, { [css.open]: showHours })}
              onClick={this.toggleHours}
            >
              <CaratDown />
            </button>
          </div>
          {showHours && (
            <ul>
              {weekdays.map((dayName, i) => (
                <li key={dayName[0]}>
                  <span>{dayName[1]}</span>
                  <ViewHours
                    hours={hours}
                    alwaysOpen={alwaysOpen}
                    dayIndex={i}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default DisplayHours;
