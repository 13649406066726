import React, { PureComponent } from 'react';
import css from './NotFound.module.scss';
import { ReactComponent as Logo } from '../../logo.svg';

class NotFound extends PureComponent {
  render() {
    return (
      <div className={css.container}>
        <a href="https://threshold360.com">
          <Logo className={css.logo} />
        </a>
        <h2>Sorry, this page isn't available.</h2>
        <p>
          The link you followed may be broken, or the page may have been
          removed.
        </p>
      </div>
    );
  }
}

export default NotFound;
