import React, { PureComponent } from 'react';
import SubPanel from './SubPanel';
import Thresholds from './Thresholds';

class AllThresholds extends PureComponent {
  render() {
    return (
      <SubPanel title="Virtual Tours">
        <Thresholds {...this.props} />
      </SubPanel>
    );
  }
}

export default AllThresholds;
