import React, { PureComponent } from 'react';
import SidePanel from '../SidePanel';
import css from './Location.module.scss';
import NotFound from '../NotFound';

function getParams(loc) {
  loc = loc || window.location.href;
  var out = {},
    match;
  var rx = /[?&]([^=&]+)(?:=([^&]+))/g;
  while ((match = rx.exec(loc))) out[match[1]] = match[2];
  return out;
}
class index extends PureComponent {
  viewerContainer = React.createRef();
  viewer = null;
  state = {
    isShowingLeadGenPanel: false
  };

  componentWillUnmount() {
    window.removeEventListener('click', this.showLeadGenPanel);
  }

  showLeadGenPanel = () => {
    this.setState({ isShowingLeadGenPanel: true });
  };
  onThresholdChange = activeStep => {
    this.setState({ activeStep });
  };
  getLocation = () => {
    if (window.llocation) {
      return Promise.resolve(window.llocation);
    }
    return fetch(
      `${process.env.REACT_APP_LOCATION_API}/${this.props.match.params.locationId}${this.props.location.search}`
    )
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.status });
          throw response;
        }
        return response.json();
      });
      //.then(data => data);
  };
  initViewer = location => {
    const urlParams = getParams();
    this.viewer = window.threshold.Viewer({
      element: this.viewerContainer.current,
      location,
      analyticsSource: urlParams.analyticsSource
        ? urlParams.analyticsSource
        : 'location-share',
      onThresholdChange: this.onThresholdChange,
      ui: {
        sidePanel: false
      }
    });
  };

  componentDidMount() {
    window.addEventListener('click', this.showLeadGenPanel);

    this.getLocation()
      .then(location => {
        if (window.gtag && location.licensee) {
          window.gtag('set', { dimension1: location.licensee.name });
        }
        this.setState({
          location,
          steps: [{ ...location }, ...(location.steps || [])]
        });
        return location;
      })
      .then(this.initViewer)
      .catch(console.error);
  }
  render() {
    const {
      location,
      steps,
      activeStep,
      error,
      isShowingLeadGenPanel
    } = this.state;
    if (!location && !error) return null;

    if (error) return <NotFound />;
    // if (window !== window.parent) {
    const urlParams = getParams();
    if (urlParams.cta_url) {
      location.cta_url = decodeURI(urlParams.cta_url);
    }
    if (urlParams.cta_label) {
      location.cta_label = decodeURI(urlParams.cta_label);
    }
    // }

    if (location.cta_url) {
      location.cta_url = !location.cta_url.match(/^https?:\/\/|^mailto|^tel/i)
        ? `//${location.cta_url}`
        : location.cta_url;
    }
    return (
      <div className={css.container}>
        {activeStep && (
          <SidePanel
            history={this.props.history}
            location={location}
            steps={steps}
            activeStep={activeStep}
            viewer={this.viewer}
            locationId={this.props.match.params.locationId}
            isShowingLeadGenPanel={isShowingLeadGenPanel}
          />
        )}
        {activeStep && activeStep.step_name && (
          <div className={css.stepName}>{activeStep.step_name}</div>
        )}
        <div ref={this.viewerContainer} className={css.container} />
      </div>
    );
  }
}

export default index;
