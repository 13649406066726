import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import ThresholdCard from '../ThresholdCard';
import css from './SidePanel.module.scss';

class Thresholds extends PureComponent {
  state = {
    tours: null
  };
  async componentDidMount() {
    const { location, preview = false, previewLength = 4 } = this.props;
    const fullList = [location, ...location.steps];
    const list = preview ? fullList.slice(0, previewLength) : fullList;

    try {
      const tours = await Promise.all(
        list.map(async step => {
          // fetch tour details which will tell us if a tour is active/hidden
          const res = await fetch(
            `${process.env.REACT_APP_TOUR_DETAILS_URL}/v1/tours/${step.step_id}`
          );
          if (res.ok) {
            return await res.json();
          }
        })
      );
      this.setState({ tours });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const {
      location,
      setActiveStepId,
      preview = false,
      previewLength = 4,
      activeStep
    } = this.props;
    const { tours } = this.state;
    const fullList = [location, ...location.steps];
    const list = preview ? fullList.slice(0, previewLength) : fullList;
    const difference = fullList.length - previewLength;
    return (
      <div className={css.thresholdList}>
        {tours &&
          list.map(step => {
            if (tours)
            {
              const selectedTour = tours.find(tour => (tour && tour.id && tour.id === step.step_id));
  
              return (
                selectedTour &&
                selectedTour.active && (
                  <div key={step.step_id} className={css.threshold}>
                    <ThresholdCard
                      step={step}
                      setActiveStepId={setActiveStepId}
                    />
                  </div>
                )
              );
            }
          })}

        {preview && (
          <Link
            to={`${window.location.pathname}/thresholds${window.location.search}`}
            className={css.moreThresholds}
          >
            +{difference} View More
          </Link>
        )}
      </div>
    );
  }
}

export default Thresholds;
