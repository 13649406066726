import React, { PureComponent } from 'react';
import css from './SidePanel.module.scss';
import Thresholds from './Thresholds';
import LocationDetails from './LocationDetails';
import MapPreview from './MapPreview';
import { Share } from '../../assets/icons';
import ShareModal from './ShareModal';

class Root extends PureComponent {
  state = {
    location: {},
    shareModal: false
  };
  toggleShare = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ shareModal: !this.state.shareModal });
  };

  render() {
    const { location, multiStep, setActiveStepId, activeStep } = this.props;
    return (
      <div>
        <div
          className={css.thumbnail}
          style={{ backgroundImage: `url(${location.thumbnail_url})` }}
        />
        {location.licensee && location.licensee.logo_url && (
          <img
            className={css.logo2}
            src={location.licensee.logo_url}
            alt={`${location.name} logo`}
          />
        )}
        <div className={css.contentContainer}>
          <div className={css.titleContainer}>
            <span className={css.locationName}>{location.name}</span>
            <button
              className={css.shareButton}
              name="share"
              type="button"
              onClick={() => {
                this.toggleShare();
              }}
            >
              <Share className={css.shareIcon} fill="#0b74a8" />
              <span>Share</span>
            </button>
          </div>
          {location.cta_url && (
            <div className="pad">
              <a
                className={css.ctaButton}
                href={location.cta_url || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                {location.cta_label || ''}
              </a>
            </div>
          )}
          <LocationDetails location={location} />
          {multiStep && (
            <div>
              <div className={css.subTitle}>Threshold Virtual Tours</div>
              <Thresholds
                location={location}
                setActiveStepId={setActiveStepId}
                activeStep={activeStep}
                preview={location.steps && location.steps.length > 3}
              />
            </div>
          )}
          <MapPreview location={location} />
        </div>
        <ShareModal
          isOpen={this.state.shareModal}
          toggle={this.toggleShare}
          location={location}
        />
      </div>
    );
  }
}

export default Root;
