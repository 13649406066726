import React, { PureComponent } from 'react';
import css from './ThresholdCard.module.scss';
import cn from 'classnames';

class ThresholdCard extends PureComponent {
  state = {
    notes: null,
    hover: false,
    shouldShowTourDetails: false
  };

  componentDidMount() {
    this.getTourNotes(this.props.step.step_id).then(data => {
      if (data && data[0]) {
        this.setState({ notes: data[0].notes });
      }
    });
    this.getTourDetails(this.props.step.step_id).then(data => {
      if (data && data.name) {
        this.setState({ name: data.name });
      } else {
        this.setState({ name: '' });
      }
    });
    /*
    this.getShowTourDetails().then(data => {
      if (data) {
        this.setState({
          shouldShowTourDetails: !!data.show_tour_details
        });
      }
    });
 
     */
  }

  async getShowTourDetails() {
    try {
      return await fetch(
        `${process.env.REACT_APP_BASE_LOCATION_API}/licensee-portal/licensees/${
          this.props.location.licensee_id
        }/locations/${this.props.location.ll_id}/shares?auth_token=${this.props
          .authToken || 'eqUj7WNhjz-X'}`
      )
        .then(data => data.json())
        .then(data => {
          const currenturl = window.location.href.substring(
            window.location.href.lastIndexOf('/') + 1
          );
          return data.shares.filter(share => share.share_id === currenturl)[0];
        });
    } catch (error) {
      console.log('getShowTourDetails: ' + JSON.stringify(error));
    }
  }

  getTourNotes(id) {
    return fetch(
      `${
        process.env.REACT_APP_TOUR_DETAILS_URL
      }/v1/tour_notes/by_tour/${id}?auth_token=${this.props.authToken ||
        'eqUj7WNhjz-X'}`
    )
      .then(res => res.json())
      .then(data => data)
      .catch(err => console.log(err));
  }

  getTourDetails(id) {
    return fetch(
      `${
        process.env.REACT_APP_TOUR_DETAILS_URL
      }/v1/tours/${id}?auth_token=${this.props.authToken || 'eqUj7WNhjz-X'}`
    )
      .then(res => res.json())
      .then(data => data)
      .catch(err => console.log(err));
  }

  render() {
    const {
      step = {},
      setActiveStepId,
      className,
      size,
      activeStep
    } = this.props;

    const { notes, hover, shouldShowTourDetails } = this.state;

    return (
      <button
        onClick={() => {
          setActiveStepId(step.step_id);
        }}
        className={cn(css.threshold, className, {
          [css.large]: size === 'large'
        })}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,.9) 0%, rgba(255,255,255,0) 50%), url(${step.thumbnail_url})`
        }}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {shouldShowTourDetails && hover && notes && (
          <p
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              padding: '5px',
              textAlign: 'center',
              border: `1px solid #e1e1e1`,
              borderRadius: '5px',
              color: 'white',
              fontSize: '14px',
              width: '100%',
              background: `rgba(0, 0, 0, 0.85)`,
              lineHeight: 1,
              display: 'flex',
              height: '6rem',
              alignItems: 'center',
              wordBreak: 'break-all',
              justifyContent: 'center'
            }}
          >
            {notes}
          </p>
        )}
        {shouldShowTourDetails && step.step_id === activeStep.step_id && notes && (
          <p
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              padding: '5px',
              textAlign: 'center',
              border: `1px solid #e1e1e1`,
              borderRadius: '5px',
              textShadow:
                '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
              color: 'white',
              fontSize: '14px',
              width: '100%',
              background: `rgba(0, 0, 0, 0.85)`,
              lineHeight: 1,
              display: 'flex',
              height: '6rem',
              alignItems: 'center',
              wordBreak: 'break-all',
              justifyContent: 'center'
            }}
          >
            {notes}
          </p>
        )}
        <span className={css.thresholdTitle}>{step.step_name}</span>
      </button>
    );
  }
}

export default ThresholdCard;
