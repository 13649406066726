import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft } from '../../../assets/icons';
import css from './SubPanel.module.scss';

class SubPanel extends PureComponent {
  render() {
    const { children, title = '' } = this.props;
    const newPath = window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');

    return (
      <div>
        <header className={css.header}>
          <Link to={newPath} className={css.backButton}>
            <ChevronLeft />
          </Link>
          <div className={css.title}>{title}</div>
        </header>
        <div className={css.content}>{children}</div>
      </div>
    );
  }
}

export default SubPanel;
