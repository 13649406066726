import React from 'react';
import { Switch, Route } from 'react-router-dom';
import css from './SidePanel.module.scss';
import Root from './Root';
import { Close } from '../../assets/icons';
import AllThresholds from './AllThresholds';

const OpenPanel = props => (
  <div id="open-panel-container" className={css.openPanel}>
    <button className={css.close} onClick={props.toggle}>
      <Close />
    </button>
    <Switch>
      <Route exact path={`/locations/${props.locationId}/thresholds`}>
        <AllThresholds {...props} />
      </Route>
      <Route>
        <Root {...props} />
      </Route>
    </Switch>
  </div>
);
export default OpenPanel;
