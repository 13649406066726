import moment from 'moment';

export function convertHours(time) {
  if (time === '1200') return 'Noon';
  if (time === '0000') return 'Midnight';
  return moment(time, 'HHmm')
    .format('h:mma')
    .replace(/:00/, '');
}

export const getWeekday = () => moment().weekday();
