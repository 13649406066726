import { hot } from 'react-hot-loader';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Location from './components/Location';
import NotFound from './components/NotFound';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/locations/:locationId"
            render={props => <Location {...props} />}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
