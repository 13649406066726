import React, { Component } from 'react';
import css from './SidePanel.module.scss';

class MapPreview extends Component {
  render() {
    const { location } = this.props;
    const long = location.longitude.toFixed(4);
    const lat = location.latitude.toFixed(4);
    const locationPin = `url-${window.encodeURIComponent(
      'https://static.threshold360.com/assets/Pin.png'
    )}(${long},${lat})`;
    return (
      <div
        className={css.locationMap}
        style={{
          backgroundImage: `url("https://api.mapbox.com/styles/v1/stepinside/cjwtbqpsd1wzm1crn348dc0sz/static/${locationPin}/${long},${lat},15/400x400?access_token=${process.env.REACT_APP_MAP_ACCESS_TOKEN}")`
        }}
      />
    );
  }
}

export default MapPreview;
