import React, { PureComponent } from 'react';
import css from './SidePanel.module.scss';
import cn from 'classnames';
import OpenPanel from './OpenPanel';
import ClosedPanel from './ClosedPanel';
import { LeadGenPanel } from './LeadGenPanel';

class SidePanel extends PureComponent {
  screenIsLarge = window.innerWidth > 700;

  state = {
    active: this.screenIsLarge
  };

  toggle = () => this.setState({ active: !this.state.active });
  closePanel = () => {
    this.setState({ active: false });
    this.props.history.replace(
      `/locations/${this.props.locationId}${window.location.search}`
    );
  };
  setActiveStepId = e => {
    this.props.viewer.setActiveStepId(e);
    this.screenIsLarge = window.innerWidth > 700;
    if (!this.screenIsLarge) {
      this.closePanel();
    }
  };

  render() {
    const {
      location,
      showTitle = true,
      locationId,
      activeStep,
      isShowingLeadGenPanel
    } = this.props;
    const { active } = this.state;
    const multiStep = location.steps && !!location.steps.length;
    const hasCTA = location.cta_url && location.cta_label;
    if (!showTitle && !multiStep) return null;

    return (
      <div className={css.wrapper}>
        <div
          className={cn(css.container, {
            [css.active]: active,
            [css.noTitle]: !location.name,
            [css.hasCTA]: hasCTA
          })}
        >
          {!active && (
            <ClosedPanel
              location={location}
              active={active}
              toggle={this.toggle}
            />
          )}
          {active && (
            <OpenPanel
              toggle={this.closePanel}
              location={location}
              multiStep={multiStep}
              setActiveStepId={this.setActiveStepId}
              activeStep={activeStep}
              locationId={locationId}
            />
          )}
        </div>
        <LeadGenPanel
          isShowingLeadGenPanel={isShowingLeadGenPanel}
          location={location}
          isActive={isShowingLeadGenPanel}
        />
      </div>
    );
  }
}

export default SidePanel;
